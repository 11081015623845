import React, { useCallback, useRef } from "react";

import {
  CSSTransition
} from "react-transition-group";

import {
  BrowserRouter as Router,
  Route,
  withRouter
} from "react-router-dom";

import { NavMenu } from "./components/Navigation/NavMenu";
import { AboutPage } from "./pages/AboutPage";
import { ProjectsPage } from "./pages/ProjectsPage";
import { SkillsPage } from "./pages/SkillsPage";

export interface PageProps {
  callback: () => void;
}

interface AppRoute {
  path: string;
  title: string;
  PageComponent: React.ComponentType<PageProps>;
}

export const appRoutes: AppRoute[] = [
  { path: "/", title: "About Me", PageComponent: AboutPage },
  { path: "/skills", title: "Skills", PageComponent: SkillsPage },
  { path: "/projects", title: "Projects", PageComponent: ProjectsPage }
];

export const MainRouter = (): JSX.Element => {
  return (
    <Router>
      <NavMenu />
      <main className="content-container">
        <TransitionRouter />
      </main>
    </Router>
  );
};

const TransitionRouter = withRouter(({ location }) => {
  const routeWrapperRef: React.RefObject<HTMLDivElement> = useRef(null);
  let routeRefs: React.RefObject<HTMLDivElement>[] = [];

  const isMatch = useCallback(
    (path: string): boolean => location.pathname === path ?? false, 
    [location]
  );

  const setWrapperHeight = useCallback(
    (height: number): void => {
      routeWrapperRef.current!.style.height = `calc(${height}px + 2em)`;
    },
    [routeWrapperRef]
  );

  // Add a ref to route-wrapper to calculate height based on the page
  // Maybe use an FaCC in the pages to pass the height instead of using states

  return (
    <div ref={routeWrapperRef} className="route-wrapper">
      {appRoutes.map(({ path, PageComponent }, index) => {
        routeRefs[index] = useRef(null);

        return (
          <Route key={index} exact path={path}>
            {() => { // Route callback ensures the transitions are loaded correctly
              return (
                <CSSTransition
                  nodeRef={routeRefs[index]}
                  in={isMatch(path)}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                  appear
                >
                  <div ref={routeRefs[index]} className="page-wrapper">
                    <PageComponent callback={() => setWrapperHeight(routeRefs[index].current!.offsetHeight)} />
                  </div>
                </CSSTransition>
              );
            }}
          </Route>
        );
      })}
      <Route path="*">
        {(location.pathname !== "/" && location.pathname !== "/projects" && location.pathname !== "/skills") && (
          <div className="page-wrapper">
            <div className="page">
              <h1>404</h1>
              <p>
                The page you're looking for doesn't exist. Are you sure you typed the URL correctly?
              </p>
            </div>
          </div>
        )}
      </Route>
    </div>
  )
});
