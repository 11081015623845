
import styles from "./header.module.css";

export const Header = (): JSX.Element => {
  return (
    <header>
      <div className={styles.greetingContainer}>
        <span className={styles.greeting}>
          Hi, my <span style={{color: "#00c0ff"}}>name</span> is
        </span>
        <h1 className={styles.name}>
          Jordan Arldt
        </h1>
      </div>
      <h2 className={styles.desc}>
        I'm a BigCommerce Product Support Engineer by day, and a Software Developer by night.
      </h2>
    </header>
  );
};
