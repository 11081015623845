import { NavLink } from "react-router-dom";

import { appRoutes } from "../../MainRouter";
import styles from "./navigation.module.css";

export const NavMenu = (): JSX.Element => {
  return (
    <nav className={styles.navigation}>
      <Menu>
        {appRoutes.map(({ path, title }, index) => (
          <MenuItem key={index} path={path} exact>{title}</MenuItem>
        ))}
      </Menu>
    </nav>
  )
};

const Menu = (props: any): JSX.Element => {
  return (
    <ul className={styles.menu}>
      {props.children}
    </ul>
  );
};

interface MenuItemProps {
  path: string;
  exact?: boolean;
  children: any;
}

const MenuItem = (props: MenuItemProps): JSX.Element => {
  return (
    <li className={styles.menuitem}>
      <NavLink
        to={props.path}
        activeClassName={styles.active}
        exact={props.exact}
      >
        <span>{props.children}</span>
      </NavLink>
    </li>
  );
};
