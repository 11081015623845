import { useState, useRef, useCallback } from "react";
import { ImageSelector } from "../ImageSelector/ImageSelector";
import styles from "./projects.module.css";

interface ProjectBlockProps {
  title: string;
  thumbnail: string;
  images: string[];
  description: string;
  frameworks: string;
  repository?: string;
  applink?: string;
  onResize: () => void;
}

export const ProjectBlock = (props: ProjectBlockProps): JSX.Element => {
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const imageContainer: React.RefObject<HTMLDivElement> = useRef(null);
  const [expanded, expand] = useState(false);

  const expandLoop = useCallback(
    () => {
      if (expanded) {
        imageContainer.current!.style.minHeight = "0px";
        imageContainer.current!.style.maxHeight = "0px";

        setTimeout(() => {
          imageContainer.current!.style.minHeight = "150px";
          imageContainer.current!.style.maxHeight = "";
          expand(!expanded);
          props.onResize();
        }, 200);
      } else {
        imageContainer.current!.style.minHeight = "";
        imageContainer.current!.style.maxHeight = "";
        expand(!expanded);
      }

      (function recursiveHeightCheck(height) {
        setTimeout(() => {
          props.onResize();
          if (containerRef.current!.offsetHeight !== height) recursiveHeightCheck(containerRef.current!.offsetHeight);
        }, 50);
      }(containerRef.current!.offsetHeight));
    },
    [expanded, props]
  );
  
  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.title}>
        {props.title}
      </div>
      <div className={expanded ? styles.expanded : styles.flex}>
        <div ref={imageContainer} className={styles.image}>
          {expanded ? (
            <ImageSelector 
              images={[...props.images]}
              alt={props.title}
            />
          ) : (
              <img src={props.thumbnail} alt={props.title} />
          )}
        </div>
        <div className={styles.content}>
          <p>{props.description}</p>
          <ul className={styles.details}>
            <li><strong>Powered by:</strong> {props.frameworks}</li>
            {props.repository && (
              <li><strong>Repository:</strong> <a href={props.repository} target="_blank" rel="noreferrer">{props.repository}</a></li>
            )}
            {props.applink && (
              <li><strong>App link:</strong> <a href={props.applink} target="_blank" rel="noreferrer">{props.applink}</a></li>
            )}
          </ul>
        </div>
      </div>
      <div className={styles.expand} onClick={() => expandLoop()}>
        {expanded ? 'Collapse' : 'Expand'}
      </div>
    </div>
  );
};
