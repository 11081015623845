import { useEffect, useState } from "react";
import { PageProps } from "../MainRouter";
import { SkillBlock } from "../components/SkillBlock/SkillBlock";

export const SkillsPage = (props: PageProps): JSX.Element => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", props.callback);
    window.addEventListener("load", props.callback); // ensure proper resizing on slow devices/networks

    return function cleanup() {
      window.removeEventListener("resize", props.callback);
      window.removeEventListener("load", props.callback);
    };
  });

  useEffect(
    () => {
      if (!isMounted) {
        setTimeout(() => {
          props.callback();
        }, 100);
      }
      setMounted(true);
    }, 
    [isMounted, props]
  );

  return (
    <div className="page">
      <section>
        <h1 className="section-title">My Skills</h1>
        <SkillBlock logo="bigc" title="BigCommerce APIs" skillLevel={100} />
        <SkillBlock logo="bigc" title="BigCommerce App Development" skillLevel={100} />
        <SkillBlock logo="bigc" title="BigCommerce Stencil Framework" skillLevel={100} />
        <SkillBlock logo="nodejs" title="NodeJS (Express)" skillLevel={90} />
        <SkillBlock logo="react" title="React" skillLevel={90} />
        <SkillBlock logo="typescript" title="TypeScript" skillLevel={90} />
        <SkillBlock logo="electron" title="ElectronJS" skillLevel={85} />
        <SkillBlock logo="handlebars" title="Handlebars" skillLevel={80} />
        <SkillBlock logo="php" title="PHP" skillLevel={45} />
        <SkillBlock logo="ruby" title="Ruby" skillLevel={35} />
      </section>
    </div>
  );
};
