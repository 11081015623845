import { useEffect, useState } from "react";
import { PageProps } from "../MainRouter";

import styles from "./about.module.css";

export const AboutPage = (props: PageProps): JSX.Element => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", props.callback);
    window.addEventListener("load", props.callback); // ensure proper resizing on slow devices/networks

    return function cleanup() {
      window.removeEventListener("resize", props.callback);
      window.removeEventListener("load", props.callback);
    };
  });

  useEffect(
    () => {
      if (!isMounted) {
        setTimeout(() => {
          props.callback();
        }, 100);
      }
      setMounted(true);
    }, 
    [isMounted, props]
  );

  return (
    <div className="page">
      <section className={styles.aboutPage}>
        <div className={styles.avatarContainer}>
          <div className={styles.avatar}></div>
          <span className={styles.email}>jordanarldt@gmail.com</span>
          <div className={styles.socials}>
            <div className={styles.socialLink}>
              <a href="https://github.com/jordanarldt" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg>
              </a>
            </div>
            <div className={styles.socialLink}>
              <a href="https://facebook.com/jordan.arldt" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <h1 className="section-title">Who am I?</h1>
          <p>Greetings! My name is Jordan and I enjoy building web applications and software. In the past year, I've been focusing on making apps that are used as tools to extend the functionality of the BigCommerce platform and automate tasks for the engineering teams.</p>
          <p>My interest in programming began when I was around 12 years old. I was creating websites using Notepad for fun, and eventually my parents purchased a JavaScript book for me so that I was able to take my learning to the next level. This was back in the days where website layouts were almost entirely tables and spacer gifs - it's wild how far web development has come since then! After some time, I branched away from web development and started focusing on other interests.</p>
          <p>Fast forward to 2020, I was blessed with an opportunity to start working at BigCommerce and this is where my passion got revived. I immediately began learning about the newest web development frameworks and took a deep-dive into NodeJS. In just one year, I was able to become very familiar with <strong>Express</strong>, <strong>React</strong>, <strong>Electron</strong>, <strong>Handlebars</strong>, and also learned the basics of other programming languages such as <strong>PHP</strong>, <strong>Python</strong>, and <strong>Ruby</strong>.</p>
          <h1 className="section-title">What makes me different?</h1>
          <p>I like to think that one of my greatest attributes which sets me apart from everyone else is my <strong>ability to learn</strong>. I've found throughout my life that I'm able to learn things at a much quicker pace than most people, which has allowed me to excel at just about anything that I've put my mind to. I'm a very logical person and try to come up with the most efficient ways to solve problems, which is especially important in programming and technical support.</p>
        </div>
      </section>
    </div>
  );
};
