import "./SkillBlock.css";

interface SkillBlockProps {
  logo: "nodejs" | "react" | "bigc" | "typescript" | "php" | "ruby" | "handlebars" | "electron";
  title: string;
  skillLevel: number;
}

export const SkillBlock = (props: SkillBlockProps): JSX.Element => {
  return (
    <div className="skillBlock">
      <div className="logoContainer">
        {props.logo === "nodejs" && <div className="logo nodejs"></div>}
        {props.logo === "react" && <div className="logo react"></div>}
        {props.logo === "bigc" && <div className="logo bigc" style={{backgroundColor: "#FFFFFF", borderRadius: "0.25em"}}></div>}
        {props.logo === "typescript" && <div className="logo typescript"></div>}
        {props.logo === "electron" && <div className="logo electron"></div>}
        {props.logo === "handlebars" && <div className="logo handlebars"></div>}
        {props.logo === "ruby" && <div className="logo ruby"></div>}
        {props.logo === "php" && <div className="logo php"></div>}
      </div>
      <div className="skillContent">
        <div className="skillTitle">
          {props.title}
        </div>
        <div className="skillBar">
          <div className="skillLevel" style={{width: `${props.skillLevel}%`}}></div>
        </div>
        <div className="skillDesc">
          <div className="skillLabel">
            Noob
          </div>
          <div className="skillLabel">
            Basics
          </div>
          <div className="skillLabel" style={{flex: "1"}}>
            Advanced
          </div>
          <div className="skillLabel">
            Expert
          </div>
        </div>
      </div>
    </div>
  );
};
