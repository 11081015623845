import { useEffect, useState } from "react";
import { PageProps } from "../MainRouter";
import { ProjectBlock } from "../components/ProjectBlock/ProjectBlock";

import pseToolbelt1 from "../assets/images/projects/psetoolbelt1.png";
import pseToolbelt2 from "../assets/images/projects/psetoolbelt2.png";
import pseToolbelt3 from "../assets/images/projects/psetoolbelt3.png";
import pseToolbelt4 from "../assets/images/projects/psetoolbelt4.png";
import pseToolbelt_vid from "../assets/images/projects/psetoolbelt_vid.gif";

import priceList1 from "../assets/images/projects/pricelistapp_1.png";
import priceList2 from "../assets/images/projects/pricelistapp_2.png";
import priceList3 from "../assets/images/projects/pricelistapp_3.png";
import priceList4 from "../assets/images/projects/pricelistapp_4.png";

export const ProjectsPage = (props: PageProps): JSX.Element => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", props.callback);
    window.addEventListener("load", props.callback); // ensure proper resizing on slow devices/networks

    return function cleanup() {
      window.removeEventListener("resize", props.callback);
      window.removeEventListener("load", props.callback);
    };
  });

  useEffect(
    () => {
      if (!isMounted) {
        setTimeout(() => {
          props.callback();
        }, 100);
      }
      setMounted(true);
    }, 
    [isMounted, props]
  );
  
  return (
    <div className="page">
      <section>
        <h1 className="section-title">My projects</h1>
        <p>Here's a list of my most notable projects. The repositories for these projects are either internal to BigCommerce or private, so you won't be able to access them unless you're part of the organization or given access.</p>
        <ProjectBlock
          title="BigCommerce PSE API Toolbelt"
          thumbnail={pseToolbelt1}
          images={[pseToolbelt1, pseToolbelt2, pseToolbelt3, pseToolbelt4, pseToolbelt_vid]}
          description="This is an executable application that assists the Product Support Engineering team by automating various API tasks on the BigCommerce platform."
          frameworks="Electron, TypeScript, React"
          repository="https://github.com/bigcommerce-labs/PSE-electron-toolbelt"
          onResize={props.callback}
        />
        <ProjectBlock
          title="BigCommerce Price List Importer/Exporter"
          thumbnail={priceList1}
          images={[priceList1, priceList2, priceList3, priceList4]}
          description="This is a BigCommerce Application that allows Enterprise merchants to export their Price List data to a CSV file, and then re-import the CSV file after edits are made. This extended the BigCommerce platform functionality, as the platform does not offer this feature natively."
          frameworks="NodeJS Express, React"
          applink="https://www.bigcommerce.com/apps/price-list-importer-exporter/"
          onResize={props.callback}
        />
      </section>
    </div>
  );
};
