import { useEffect, useState, useRef } from "react";
import styles from "./images.module.css";

interface ImageSelectorProps {
  images: string[];
  alt: string;
}

export const ImageSelector = (props: ImageSelectorProps) => {
  const [selected, setSelected] = useState(0);
  const [mounted, setMounted] = useState(false);
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const imageRef: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(
    () => {
      if (!mounted) {
        setMounted(true);
        containerRef.current!.style.height = "max-content";
        imageRef.current!.style.width = "500px";
        imageRef.current!.style.height = "315px";
      }
    },
    [mounted]
  );

  return (
    <div ref={containerRef} className={styles.container}>
      <div ref={imageRef} className={styles.image}>
        <a href={props.images[selected]} target="_blank" rel="noreferrer">
          <img src={props.images[selected]} alt={props.alt} />
        </a>
      </div>
      <div>
        {props.images.map((image: string, index: number) => (
          <div
            key={index} 
            className={styles.thumbnail} 
            data-selected={selected === index ? "true" : "false"}
            onClick={() => selected !== index && setSelected(index)}
          >
            <img src={image} alt={`${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};
