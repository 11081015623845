// import React from "react";

import { Header } from "./components/Header/Header";
import { MainRouter } from "./MainRouter";

// Main portfolio router/layout
export const App = (): JSX.Element => {
  return (
    <div className="main-container">
      <Header />
      <MainRouter />
    </div>
  );
};
